<template>
  <div class="hello">
    <h1>Íhlutir vörulisti</h1>
    Fljótleg leit í vörulista <a href="http://www.ihlutir.is">Íhluta</a>. Mér finnst glatað að leita í excel skjali, og því var þessu hent saman.
    <hr />
    <b-form-input autofocus debounce="500" v-model="searchString" id="input-large" size="lg" placeholder="Leitarstrengur"></b-form-input>
    {{ filtered.length }} niðurstöður af {{ vorulisti.length }} vörunúmerum.<br>
    Ef þú fannst ekki það sem þú leitaðir að, prófaðu þá að nota færri orð eða nota brot úr orði. T.d. ef þú ert að leita að spennubreyti/spennugjafa þá getur borgað sig að leita að "spennu"

    <b-row v-if="!isBusy">
      <b-col><b-pagination @change="pageChange" align="fill" v-model="currentPage" :per-page="perPage" :total-rows="filtered.length" aria-controls="vorulistiTable"></b-pagination></b-col>
      <b-col cols="2">Fjöldi á síðu</b-col><b-col cols="2"><b-form-input v-model="perPage" id="input-large" placeholder="Fjöldi á síðu"></b-form-input></b-col>
    </b-row>
    <b-table id="vorulistiTable" hover :items="filtered" :per-page="perPage" :current-page="currentPage" :busy="isBusy" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Sæki vörulista...</strong>
        </div>
      </template>
    </b-table>
    <b-pagination @change="pageChange" align="fill" v-model="currentPage" :per-page="perPage" :total-rows="filtered.length" aria-controls="vorulistiTable"></b-pagination>
    Vörulisti frá 1. febrúar 2021. Íhlutir eru í Skipholti 7. Þessi síða tengist fyrirtækinu á engan hátt, heldur er smíðuð af óháðum aðila.
  </div>
</template>

<script>

export default {
  name: 'Vorulisti',
  props: {
    msg: String
  },
  data: function () {
    return {
      isBusy: true,
      searchString: "",
      perPage: 100,
      currentPage: 1,
      vorulisti: [],
      sortBy: 'vnr',
      sortDesc: false,
      fields: [
        {key: 'vnr', label: 'Vörunúmer', sortable: true},
        {key: 'lysing', label: 'Lýsing', sortable: true},
        {key: 'lysing_birgja', label: 'Lýsing birgja', sortable: true},
        {key: 'verd_vsk', label: 'Verð m. vsk', sortable: true},
      ]
    }
  },
  methods: {
    pageChange(page) {
      if (this.searchString.length > 1)
        this.$router.push({name: 'search', params: {page: page, searchString: this.searchString}})
      else
        this.$router.push({name: 'front', params: {page: page}})
    }
  },
  created: function () {
    var that = this
    fetch('https://www.ihlutir.com/vl.php', {mode: 'cors'})
      .then(response => response.json())
      .then(function (data) {
        that.vorulisti = data
        that.isBusy = false

        if (that.$route.params.searchString)
          that.searchString = that.$route.params.searchString;

        if (that.$route.params.page > 1)
          that.currentPage = that.$route.params.page;

      });
  },
  watch: {
    searchString: function () {
      this.currentPage = 1
      if (this.$route.params.searchString != this.searchString)
        this.$router.push({name: 'search', params: {searchString: this.searchString, page: 1}})
    }
  },
  computed: {
    filtered: function() {
      var that = this
      return this.vorulisti.filter(function(obj) {
        return Object.keys(obj).some(function(key) {
          return obj[key].toLowerCase().includes(that.searchString.toLowerCase());
        })
      })
    },
    page: function () {
      if (this.$route.params.page > 1)
        return this.$route.params.page
      else return 1
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
