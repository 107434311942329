import Vue from 'vue'
//import { BootstrapVue } from 'bootstrap-vue' // IconsPlugin
import { SpinnerPlugin, LayoutPlugin, PaginationPlugin, FormInputPlugin, TablePlugin } from 'bootstrap-vue'
import VueRouter from 'vue-router'

import App from './App.vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
//Vue.use(BootstrapVue)
Vue.use(SpinnerPlugin)
Vue.use(LayoutPlugin)
Vue.use(PaginationPlugin)
Vue.use(FormInputPlugin)
Vue.use(TablePlugin)
//Vue.use(IconsPlugin)
Vue.use(VueRouter)

Vue.config.productionTip = false

import Vorulisti from './components/Vorulisti.vue'
const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/', component: Vorulisti},
    { name: 'front', path: '/:page', component: Vorulisti},
    { name: 'search', path: '/s/:searchString/:page', component: Vorulisti},
    { path: '/s/:searchString', component: Vorulisti},
  ]
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
